<template>
  <section class="event-charts">
    <div class="distributed">
      <h3 class="title">舆情分布图</h3>
      <div class="noData" v-show="noDistributed">暂无数据</div>
      <div id="distributedPie" class="item" ref="distributedPie"></div>
    </div>
    <div class="drend">
      <h3 class="title">舆情走势</h3>
      <div class="noData" v-show="noDrend">暂无数据</div>
      <div id="drendLine" class="item" ref="drendLine"></div>
    </div>
    <div class="media-ranking">
      <h3 class="title">媒体排行</h3>
      <div class="noData" v-show="noMediaRanking">暂无数据</div>
      <div id="mediaRankingBar" class="item" ref="mediaRankingBar"></div>
    </div>
  </section>
</template>

<script>
import { getOpinionDirection, mediaRanking, publicOpinionDistribution } from '@/api/eventEvolution'
export default {
  data () {
    return {
      charts: [],
      noDistributed: false,
      noDrend: false,
      noMediaRanking: false,
      current: 1,
      pageSize: 10
    }
  },
  mounted () {
    this.evolutionId = this.$route.params.id
    this.pieChart()
    this.barChart()
    this.lineChart()
  },
  methods: {
    async  pieChart () {
      // 基于准备好的dom，初始化echarts实例
      let pieChart = this.$echarts.init(this.$refs.distributedPie)
      const res = await publicOpinionDistribution({ id: this.evolutionId, current: this.current, pageSize: this.pageSize })
      if (res.data.length === 0) {
        this.noDistributed = true
      } else {
        this.noDistributed = false
      }
      this.charts = res
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: function(data){ return `${data.seriesName} <br/>${data.name} : ${data.value} (`+data.percent.toFixed(2)+"%)";}
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        legend: {
          x: 'center',
          y: 'bottom',
          data: res.legend
        },
        color: ['#3372C7', '#F56C6C', '#ffdb8a'],
        series: [
          {
            name: '情感类型',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '50%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              emphasis: {
                show: true
              }
            },
            lableLine: {
              normal: {
                show: false
              },
              emphasis: {
                show: true
              }
            },
            data: this.charts.data
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      pieChart.setOption(option)
    },
    async lineChart () {
      // 舆情走势图
      // 基于准备好的dom，初始化echarts实例
      let lineChart = this.$echarts.init(this.$refs.drendLine)
      const res = await getOpinionDirection({ evolutionId: this.evolutionId })
      if (res.data.xAxis.length === 0) {
        this.noDrend = true
      } else {
        this.noDrend = false
      }
      // 指定图表的配置项和数据
      let option = {
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        legend: {
          data: ['负面', '正面', '敏感']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.data.xAxis
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        color: ['#F56C6C', '#3372C7', '#ffdb8a'],
        series: [
          {
            name: '总量',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              normal: {
                color: 'rgba(128, 128, 128, 0)' // 仍为透明
              }
            },
          }, {
            name: '负面',
            type: 'line',
            stack: '总量1',
            symbol: 'none',
            data: res.data.series[0].yBadData,
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(245, 108, 108, 1)'
                },
                {
                  offset: 1,
                  color: 'rgba(245, 108, 108, .5)'
                }
              ])
            }
          },
          {
            name: '正面',
            type: 'line',
            stack: '总量2',
            symbol: 'none',
            data: res.data.series[0].yNotBadData,
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(51, 114, 199, 1)'
                },
                {
                  offset: 1,
                  color: 'rgba(51, 114, 199, .5)'
                }
              ])
            }
          },
          {
            name: '敏感',
            type: 'line',
            stack: '总量3',
            symbol: 'none',
            data: res.data.series[0].yMGData,
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255, 219, 138, 1)'
                },
                {
                  offset: 1,
                  color: 'rgba(255, 219, 138, .5)'
                }
              ])
            }
          }
        ]
      }

      // 使用刚指定的配置项和数据显示图表。
      lineChart.setOption(option)
    },
    async barChart () {
      // 媒体排行图
      // 基于准备好的dom，初始化echarts实例
      let barChart = this.$echarts.init(this.$refs.mediaRankingBar)
      const res = await mediaRanking({ id: this.evolutionId })
      if (res.data.xAxis.length === 0) {
        this.noMediaRanking = true
      } else {
        this.noMediaRanking = false
      }
      // 指定图表的配置项和数据
      let option = {
        color: ['#3372C7'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {
        //       pixelRatio: 2
        //     }
        //   }
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: res.data.xAxis,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data: res.data.series,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff'
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      barChart.setOption(option)
    }
  }
}
</script>

<style lang="scss">
.event-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .noData{
    width: 100%;
    text-align: center;
    position: absolute;
    color: #898989;
    top: 160px;
  }
  .distributed {
    width: 40%;
    height: 330px;
    margin-top: 30px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 2px;
    position: relative;
    .title {
      font-size: 16px;
      color: #333;
      margin: 0;
      margin-left: 18px;
      margin-top: 20px;
    }
    .item {
      width: 100%;
      height: calc(100% - 60px);
    }
  }
  .drend {
    width: 59%;
    margin-left: 1%;
    height: 330px;
    margin-top: 30px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 4px;
    position: relative;
    .title {
      font-size: 16px;
      color: #333;
      margin: 0;
      margin-left: 18px;
      margin-top: 20px;
    }
    .item {
      width: 100%;
      height: calc(100% - 60px);
    }
  }
  .media-ranking {
    width: 100%;
    height: 330px;
    margin-top: 30px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 4px;
    position: relative;
    .title {
      font-size: 16px;
      color: #333;
      margin: 0;
      margin-left: 18px;
      margin-top: 20px;
    }
    .item {
      width: 100%;
      height: calc(100% - 60px);
    }
  }
}
</style>
