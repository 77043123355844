<template>
  <section class="evolution-details">
    <section class="info">
      <span class="goBack"><a href="javascript:history.back(-1);">< 返回</a></span>
      <h3 class="info-title">事件名称：{{ form.name }}</h3>
      <div class="info-content">
        <span class="info-msg">事件信息</span>
        <div class="words-wrap">
          <span class="keyword">关键词：{{ form.keyword?form.keyword:'暂无' }}</span>
          <span class="cooccurrenceWord">同现词：{{ form.cooccurrenceWord?form.cooccurrenceWord:'暂无' }}</span>
        </div>
        <div class="time-wrap">
<!--          <span class="crate-time">创建日期：2018-7-08</span>-->
          <span class="start-time">起始日期：{{ form.startDate?dateFormat(form.startDate):'' }}</span>
          <span class="end-time">结束日期：{{ form.endDate?dateFormat(form.endDate):'' }}</span>
        </div>
      </div>
    </section>
    <div class="tab-wrap">
      <el-button type="text" @click="getCharts" :class="{'cover' : type === 'charts'}">事件图表</el-button>
      <el-button type="text" @click="getTimeline" :class="{'cover': type === 'timeline'}">演化详情</el-button>
    </div>
    <div id="div4">
      <el-button type="primary" size="mini" @click="showExport=true;fileName=''" :disabled="chartsLoading||timeLineLoading">
        数据下载
      </el-button>
    </div>
    <my-timeline :list="list" :handleEdit="handleEdit" :handleRemove="handleRemove" v-loading="timeLineLoading" v-if="type === 'timeline'"></my-timeline>
    <event-charts v-loading="chartsLoading" v-else/>
    <div class="noData" v-show="isNodata">暂无数据</div>
    <div class="isbottom">
       <i class="fa fa-spinner fa-pulse"></i>
       <span>加载中，请稍候</span>
    </div>
    <el-dialog title="导出" width="40%" :visible.sync="showExport">
      <el-form>
        <el-form-item label="文件名称" label-width="120px">
          <el-input v-model="fileName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExport=false">取 消</el-button>
        <el-button type="primary" @click="viewsExportExcel">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span class="prompt"><i class="el-icon-warning"></i>是否从本事件中移除该数据？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="conDel">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import MyTimeline from '@/components/MyTimeline'
import EventCharts from '@/components/EventCharts'
import { eventEvolutionDetails, deleteEventMap,dataExcelExport } from '@/api/eventEvolution'
import { apiBaseURL } from '@/utils/request'
import { getToken } from '@/utils/auth'
import { exportWord } from '@/api/detection'
import $ from 'jquery'

export default {
  components: {
    MyTimeline,
    EventCharts
  },
  data () {
    return ({
      showExport:false,
      fileName:'',
      timeLineLoading: true,
      chartsLoading: true,
      id: '',
      current: 1,
      pageSize: 10,
      isNodata: false,
      form: {
        name: '',
        createDate: '',
        endDate: ''
      },
      list: [],
      dialogVisible: false,
      type: 'timeline',
      removeId: '',
      flag: true
    })
  },
  methods: {
    handleRemove (id) {
      this.removeId = id
      this.dialogVisible = true
    },
    dateFormat (val) {
    	 var time = val.replace(/-/g, '/').substr(0, val.indexOf('.'))
      var t = new Date(time)
      var year = t.getFullYear()
      var month = t.getMonth() + 1
      var day = t.getDate()
      var hour = t.getHours()
      var min = t.getMinutes()
      var sec = t.getSeconds()
      var newTime = year + '-' +
     　　  (month < 10 ? '0' + month : month) + '-' +
    　　   (day < 10 ? '0' + day : day) + ' ' +
     　　  (hour < 10 ? '0' + hour : hour) + ':' +
     　　  (min < 10 ? '0' + min : min) + ':' +
     　　  (sec < 10 ? '0' + sec : sec)
      return newTime
    },
    async conDel () {
      const res = await deleteEventMap({ selfId: this.removeId })
      this.current = 1
      this.list = []
      this.eventEvolutionDetails(this.id)
      this.dialogVisible = false
      if (res.code === 0) {
        this.$message.success('删除新闻成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    handleEdit (id) {
      console.log(id)
      this.$router.push(`/eventEvolution/eventEdit/${id}`)
    },
    handleClose () {
      this.dialogVisible = false
    },
    getCharts () {
      this.type = 'charts'
      window.removeEventListener('scroll', this.scrollBottom, false)
    },
    getTimeline () {
      this.type = 'timeline'
      this.current = 1
      this.list = []
      this.eventEvolutionDetails(this.id)
      window.addEventListener('scroll', this.scrollBottom)
    },
    async eventEvolutionDetails (id) {
      try {
        const res = await eventEvolutionDetails({ id: id, current: this.current, pageSize: this.pageSize })
        this.form = res.data.list
        $.each(res.data.listNews,(index,val)=>{
          this.list.push(val)
        })
        this.flag = true
        if(res.data.listNews.length < 10){
          this.flag = false
          if(res.data.listNews.length === 0){
            this.isNodata = true
          }
        }
      } catch (err) {
        console.log(err)
      }
      $('.isbottom').hide()
      this.timeLineLoading = false
      this.chartsLoading = false
    },
    scrollBottom() {
     /* var scrollHeight = document.documentElement.scrollTop;      // 滚动高度
      var height = $("body").get(0).scrollHeight;	// 内容高度
      var viewHeight = $("body").height();		// 可见高度 */
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;//可视区的高度
      this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;//滚动条滚动时，距离顶部的距离
      this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;//滚动条的总高度
      if(this.scrollTop+this.clientHeight>=this.scrollHeight){
        // console.log(this.current)
        if(this.flag === false) return
        this.flag = false
        $('.isbottom').show()
        this.current ++
        this.timer=setTimeout(()=>{   //延迟200ms加载数据
          this.eventEvolutionDetails(this.id)							// 调用方法
        },200)
      }

    },
    async viewsExportExcel () {
      this.showExport= false
      try {
        let token = getToken()
        // let down = document.createElement('a')
        var res = await dataExcelExport('eventId=' + this.id + '&token=' + token +'&fileName='+this.fileName,)
        if(res.code == 0){
          this.$message.success(res.msg)
        }
      } catch (error) {
        this.$message.error('服务器内部异常')
      }
    },
    async viewsExportWord () {
      if (this.multipleSelection.length === 0) {
        this.$alert('请至少选择一条数据', '提示', {
          confirmButtonText: '确定'
        })
      } else {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          console.log(i)
          this.listSN.push(this.multipleSelection[i].sn)
        }
        try {
          let token = getToken()
          const res = await exportWord({ snList: this.listSN })
          console.log(res)
          if (res.code === 1) {
            let down = document.createElement('a')
            down.href = apiBaseURL + '/data/downloadWord?doc=' + res.msg + '&token=' + token
            down.click()
          }
          this.$message.success('下载成功')
        } catch (error) {
          this.$message.error('服务器内部异常')
        }
        this.listSN = []
      }
    }
  },

  mounted () {
    this.id = this.$route.params.id
    this.eventEvolutionDetails(this.id)
    // 添加滚动事件，检测滚动到页面底部
    if(this.type === 'timeline'){
      window.addEventListener('scroll', this.scrollBottom)
    }
  },
  destroyed() {
  	//销毁监听函数
  	window.removeEventListener("scroll",this.scrollBottom);
  }
}
</script>

<style lang="scss">
.evolution-details {
  .goBack {
    float: right;
    color: #858585;
    font-size: 15px;
    margin: 18px 24px;
  }
  .info {
    height: 155px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
    border-radius: 2px;
    .info-title {
      margin: 0;
      font-size: 16px;
      color: #333;
      padding: 20px;
      border-bottom: 1px solid #F0F0F3;
    }
    .info-content {
      padding: 10px 20px;
      .info-msg {
        color: #333;
        font-size: 14px;
        font-weight: 500;
      }
      .time-wrap, .words-wrap {
        margin-top: 10px;
        color: #333;
        font-size: 14px;
        span {
          width: 240px;
          margin-right: 15px;
          display: inline-block;
        }
      }
    }
  }
  .tab-wrap {
    height: 22px;
    margin: 30px 0;
    display: inline-block;
    .el-button  {
      padding: 0;
      color: #333;
      font-size: 16px;
      font-weight: bolder;
      &.cover {
        font-size: 20px;
      }
    }
  }
  .el-dialog {
    .el-dialog__body {
      padding: 10px 20px;
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;
        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }

  div#div4 {
    float: right;
    display: inline-block;
    margin-top: 25px;
  }
}
.noData{
  width: 100%;
  text-align: center;
  // position: absolute;
  color: #898989;
  margin-top: 100px;
}
.isbottom{
  text-align: center;
  margin: 10px auto;
  display: none;
  .fa{
    margin-right: 10px;
  }
}
</style>
