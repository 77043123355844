<template>
  <ul class="my-timeline">
    <li v-for="(item, index) in list" :key="index" :class="['my-timeline-item', index % 2 === 0 ? 'left' : 'right']">
      <div class="my-timeline-item__tail"></div>
      <div class="my-timeline-item__node my-timeline-item__node--normal"></div>
      <div class="my-timeline-item__content" v-if="index % 2 === 0">
        <span class="introduction" @click="getNewsDetailsThroughsn(item.sn)">{{ item.title }}</span>
        <span class="source">来源：{{ item.sourceName }}</span>
        <div class="controller">
<!--          <el-button type="text" @click="handleEdit(item.id)">修改</el-button>-->
          <el-button type="text" class="danger" @click="handleRemove(item.id)">删除</el-button>
        </div>
      </div>
      <div class="time-source">
        <span class="time">{{ dateFormat(item.publishDateStd) }}</span>
<!--        <img src="@/assets/images/weibo.png" class="source" alt="">-->
      </div>
      <div class="my-timeline-item__content" v-if="index % 2 !== 0">
        <span class="introduction" @click="getNewsDetailsThroughsn(item.sn)">{{ item.title }}</span>
        <span class="source">来源：{{ item.sourceName }}</span>
        <div class="controller">
<!--          <el-button type="text" @click="handleEdit(item.id)">修改</el-button>-->
          <el-button type="text" class="danger" @click="handleRemove(item.id)">删除</el-button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  methods: {
    getNewsDetailsThroughsn (sn) {
      const { href } = this.$router.resolve({
        name: 'newsDetail',
        params: {
          sn: sn
        }
      })
      window.open(href, '_blank')
    // this.$router.push(`monitor/list/${this.$route.params.id}/${this.$route.params.nodeId}`)
    },
    dateFormat (val) {
    	 var time = val.replace(/-/g, '/').substr(0, val.indexOf('.'))
       var t = new Date(time)
       var year = t.getFullYear()
       var month = t.getMonth() + 1
       var day = t.getDate()
       var hour = t.getHours()
       var min = t.getMinutes()
       var sec = t.getSeconds()
       var newTime = year + '-' +
     　　  (month < 10 ? '0' + month : month) + '-' +
    　　   (day < 10 ? '0' + day : day) + ' ' +
     　　  (hour < 10 ? '0' + hour : hour) + ':' +
     　　  (min < 10 ? '0' + min : min) + ':' +
     　　  (sec < 10 ? '0' + sec : sec)
       return newTime
     }
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    handleRemove: {
      type: Function,
      required: true
    },
    handleEdit: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scope>
.my-timeline {
  padding: 0;
  .my-timeline-item {
    position: relative;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    .my-timeline-item__tail {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      border-left: 5px solid rgba(199,202,208,1);
    }
    .my-timeline-item__node {
      position: absolute;
      background-color: #e4e7ed;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .my-timeline-item__node--normal {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -135%);
      width: 12px;
      height: 12px;
    }
    .introduction {
      width: 100%;
      overflow:hidden; //超出的文本隐藏
      text-overflow:ellipsis; //溢出用省略号显示
      white-space:nowrap; //溢出不换行
      cursor: pointer;
    }
    .introduction:hover, .danger:hover {
      opacity: .7;
    }
    &.left {
      .my-timeline-item__content {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        box-shadow:0px 0px 6px 0px rgba(53,64,82,0.06);
        border-radius:2px;
        padding: 16px;
        height: 120px;
        position: relative;
        left: 8%;
        .controller {
          text-align: right;
          .el-button {
            padding: 0;
          }
          .danger {
            color: #FF0000;
          }
        }
      }
      .time-source {
        position: relative;
        right: -12%;
        width: 40%;
        height: 24px;
        display: flex;
        align-items: center;
        .time {
          color: #000;
          font-size: 16px;
        }
        .source {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }
      }
    }
    &.right {
      .my-timeline-item__content {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        box-shadow:0px 0px 6px 0px rgba(53,64,82,0.06);
        border-radius:4px;
        padding: 16px;
        height: 120px;
        position: relative;
        left: 12%;
        .controller {
          text-align: right;
          .el-button {
            padding: 0;
          }
          .danger {
            color: #FF0000;
          }
        }
      }
      .time-source {
        position: relative;
        right: -8%;
        width: 40%;
        height: 24px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .time {
          color: #000;
          font-size: 16px;
        }
        .source {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
